.app {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    gap: 10px;
    background: linear-gradient(to right bottom, rgba(247, 7, 7, 0.603), rgba(0, 0, 255, 0.678));
    width: 100%;
    height: fit-content;

    .form {
        display: flex;
        position: sticky;
        top: 10px;
        height: 5%;
        justify-content: center;
        gap: 10px;
        width: 50%;

        .input {
            border: 2px solid white;
            border-radius: 10px;
            color: white;
            background-color: rgba(238, 84, 84, 0.856);
            padding: 5px;
            width: 800px;
            outline: none;
        }

        button {
            border: 2px solid rgba(255, 0, 0, 0.589);
            border-radius: 10px;
            font-weight: bold;
            transition: 500ms;

            &:hover {
                transform: scale(105%);
                color: white;
                background-color: rgba(112, 112, 238, 0.61);
            }
        }
    }

    .list {
        display: grid;
        gap: 20px;
        width: 100%;
        grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));

        .container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            //border-bottom: 2px solid white;
            height: 300px;
            width: 250px;
            overflow: hidden;

            img {
                height: 200px;
                width: 200px;

            }

            p {
                text-size-adjust: initial;
                color: beige;
                margin-left: auto;
                margin-right: auto;
            }
        }

    }
}

